import { Link } from "gatsby";
import React from "react";
import { useLocation } from "@reach/router";
import "./SideBarStyle.css";
import stmReport from "../../assets/about/StM Strategic Plan 2024-2026.pdf";

interface SideBarProps {
  title?: string;
  items: Array<any>;
}

const SideBar = (props: SideBarProps) => {
  const { items, title } = props;
  const path = useLocation();

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-header d-flex flex-c-m flex-col">
        <div className="title">{title}</div>
      </div>
      <div className="sidebar-links">
        <ul>
          {items.map((links) => (
            <li
              className={path.pathname === `/${links.slug}` ? "active" : ""}
              key={links.slug}
            >
              <Link to={`/${links.slug}`}>{links.name}</Link>
            </li>
          ))}
            <li><a href={stmReport}>Strategic Plan</a></li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
